import React, { useContext, useState, useCallback, useEffect } from "react"
import { navigate } from "gatsby"
import {
  Header,
  Footer,
  Loader,
  DCXButton,
  InputForRut,
  InputForNames,
  InputForLastnames,
  InputForPhone,
  InputForEmail,
  InputForIncome,
  Intro,
} from "../../components"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { checkoutFormImage } from "../../assets"
import { CarContext } from "../../context"
// import { KeyboardContext } from "../../context"
import { updateLead } from "../../api"
import { urlWithSession } from "../../utils"
import { URLS } from "../../constants"
import { useWithCredit } from "../../hooks"

const CheckoutStep2 = () => {
  // const { hideKeyboard } = useContext(KeyboardContext)
  const { checkoutData, setCheckoutData, appLoading } = useContext(CarContext)
  const [withCredit] = useWithCredit()

  const title = !withCredit
    ? "Para reservar tu auto necesitamos algunos datos"
    : "Para aprobar tu crédito necesitamos algunos datos"

  const [errors, setErrors] = useState({
    name: true,
    lastname: true,
    rut: true,
    phone: true,
    email: true,
    income: withCredit,
  })

  const handleChange = useCallback(
    ({ name, value, hasError }) => {
      setCheckoutData(prev => ({
        ...prev,
        client: {
          ...prev.client,
          [name]: value,
        },
      }))
      setErrors(prev => ({
        ...prev,
        [name]: hasError,
      }))
    },
    [setCheckoutData, setErrors]
  )

  const handleCheckbox = e => {
    setCheckoutData(prev => ({
      ...prev,
      agreeTC: e.target.checked,
    }))
  }

  // Disable if there is any error or there is any null/empty value
  const shouldDisableButton =
    Object.values(errors).reduce((acum, value) => acum || value, false) ||
    Object.entries(checkoutData.client).some(([key, value]) =>
      key === "income" ? withCredit && !value : !value
    ) ||
    !checkoutData.agreeTC

  // Loading while requesting lead update
  const [loading, setLoading] = useState(true)

  // Navigate home if lead uuid not on context
  useEffect(() => {
    if (appLoading) return
    if (!checkoutData.leadUUID) navigate(urlWithSession("/home"))
    else setLoading(false)
  }, [checkoutData.leadUUID, appLoading])

  return (
    <>
      <Header />
      <div className="checkout-step2 standard-page">
        <div className="content">
          <div className="page-title">Ingresa tus datos</div>
          <Intro image={checkoutFormImage} title={title} />
          <div className="form">
            {!appLoading && (
              <>
                <InputForNames
                  name="name"
                  onChange={handleChange}
                  // onEnterPress={() => {
                  //   hideKeyboard()
                  //   document.getElementById("input-for-name").blur()
                  // }}
                  // onTabPress={() => {
                  //   console.log("focussing next input")
                  //   document.getElementById("input-for-lastname").focus()
                  //   console.log("hiding keyboard bc tab pressed")
                  //   // hideKeyboard()
                  // }}
                  value={checkoutData.client.name}
                />

                <InputForLastnames
                  name="lastname"
                  onChange={handleChange}
                  value={checkoutData.client.lastname}
                />

                <InputForRut
                  name="rut"
                  onChange={handleChange}
                  value={checkoutData.client.rut}
                />

                <InputForPhone
                  name="phone"
                  onChange={handleChange}
                  value={checkoutData.client.phone}
                />
                <InputForEmail
                  name="email"
                  onChange={handleChange}
                  value={checkoutData.client.email}
                />
                {withCredit ? (
                  <InputForIncome
                    elementId="checkout-input-for-income"
                    name="income"
                    onChange={handleChange}
                    value={checkoutData.client.income}
                  />
                ) : (
                  <span></span>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkoutData.agreeTC}
                      onChange={handleCheckbox}
                    />
                  }
                  label={
                    <span className="terms">
                      Acepto{" "}
                      <a
                        href={URLS.termsAndConditions}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Términos y Condiciones
                      </a>
                    </span>
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className="buttons">
          <DCXButton
            type="secondary"
            text="ATRÁS"
            onClick={() => window.history.back()}
          />
          <DCXButton
            type="primary"
            text={withCredit ? "SOLICITAR CRÉDITO" : "SIGUIENTE"}
            disabled={shouldDisableButton}
            onClick={() => {
              setLoading(true)
              updateLead(checkoutData.leadUUID, {
                ...checkoutData.client,
              })
                .then(({ data }) => {
                  setCheckoutData(prev => ({
                    ...prev,
                    preApproved: data.preApproved,
                  }))
                  navigate(urlWithSession("/checkout/info"))
                  setLoading(false)
                })
                .catch(() => {
                  setLoading(false)
                  window.alert(
                    "Hubo un problema procesando su reserva. Por favor intente más tarde."
                  )
                })
            }}
          />
        </div>
      </div>
      <Footer />

      {loading && <Loader />}
    </>
  )
}

export default CheckoutStep2
